<template>
  <!-- We get better performance by turning the css off -->
  <transition appear :css="false" @before-enter="beforeEnter" @enter="enter">
    <slot />
  </transition>
</template>

<script>
export default {
  props: {
    duration: {
      type: Number,
      default: 1000,
    },
    delay: {
      type: Number,
      default: 1500,
    },
    transition: Array,
  },
  methods: {
    beforeEnter(el) {
      el.style.opacity = 0;
      //   if (this.transition)
      //     el.style.transform = "translate(" + this.transition.join(",") + ")";
    },
    enter(el) {
      el.style.transition = "opacity 700ms ease-in-out";
      //   el.style.transition =
      //     "opacity " + this.duration + "ms ease-in-out " + this.delay + "ms";
      //   let transitions =
      //     "opacity " + this.duration + "ms ease-in-out " + this.delay + "ms";
      //   if (this.transition)
      //     transitions +=
      //       ", transform " +
      //       this.duration +
      //       "ms ease-in-out " +
      //       this.delay +
      //       "ms";

      //   el.style.transition = transition;

      getComputedStyle(el);
      setTimeout(() => {
        el.style.opacity = 1;
        // el.style.trasform = "initial";
      });
    },
  },
};
</script>

<style scoped>
</style>