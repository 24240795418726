import Vue from "../lib/vue.js";
import WindowsizeMixin from "../lib/WindowsizeMixin";
import store from '../lib/store';
import "../../css/styles.scss";

import Login from "../components/Login";

const app = new Vue({
    store,
    mixins: [ WindowsizeMixin ],
    components: {
        "login-component": Login,
    },
});

window.addEventListener("DOMContentLoaded", () => {
    // dynamically load v-animate-css since it can only be imported once the DOM is ready
    import("v-animate-css").then(({ default: VAnimateCss }) => {
        Vue.use(VAnimateCss);
        app.$mount("main");
    });
});