const WindowsizeMixin = {
    data() {
        return {
            window: {
                width: 0,
                height: 0
            }
        };
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
};

export default WindowsizeMixin;