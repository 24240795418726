<template>
  <Appear :transition="['100px', 0]">
    <b-container class="fill-height login-container page" fluid>

      <!--      Spinning Loader     -->

      <div class="loader-wrapper" v-if="showLoader" ref="loader">
        <div class="loader-4 center"><span></span></div>
      </div>

      <!--      End of loader        -->

      <img
          src="../../img/login_images/hearingaid1.png"
          v-animate-css="animationObject1"
          class="login-background image1 floating-image no-user-selection"
          style="z-index: 0"
      />
      <img
          src="../../img/login_images/hearing-aid2.png"
          v-animate-css="animationObject2"
          class="login-background image2 floating-image no-user-selection"
          style="z-index: 0"
      />
      <img
          src="../../img/login_images/enzo-q.png"
          v-animate-css="animationObject3"
          class="image3 floating-image no-user-selection"
          style="
            height: 120px;
            width: 80px;
            position: absolute;
            opacity: 0.8 !important;
          "
      />
      <img
          src="../../img/login_images/widex-tv-play-1000-1000.png"
          v-animate-css="animationObject4"
          class="image4 floating-image no-user-selection"
          style="height: 120px; width: 80px; position: absolute; z-index: 0"
      />
      <img
          src="../../img/login_images/RS_TV_Streamer_white_rectangular.png"
          v-animate-css="animationObject5"
          class="image5 floating-image no-user-selection"
          style="height: 120px; width: 80px; position: absolute"
      />
      <img
          src="../../img/login_images/enzo-q.png"
          v-animate-css="animationObject6"
          class="image6 floating-image no-user-selection"
          style="height: 90px; width: 60px; position: absolute; z-index: 0"
      />
      <img
          src="../../img/login_images/hearing-aid2.png"
          class="login-background image8 floating-image no-user-selection"
          v-animate-css="animationObject8"
          style="z-index: 0"
      />

      <b-row align="center" justify="center" class="mx-auto">
        <b-col sm="8" md="8" cols="12" class="main-column mx-auto">
          <b-card class="shadow-lg main-center-card">
            <b-row >
              <b-col md="2" class="side-window pr-0 pl-0 pt-0">
                <img
                    src="../../img/login_images/Login-window.jpg"
                    class="grey darken-4 login-image no-user-selection"
                />
                <img
                    src="../../img/Audoora_Services_Logos_Order_white.svg"
                    class="login-logo no-user-selection"
                />
              </b-col>
              <b-col cols="12" md="10" class="mx-auto p-0" style="text-align:left;">
                <b-card-text class="mt-0 pa-7">
                  <div class="mb-4" style="position: relative">
                    <h3 class="login-heading" ref="header-login" :style="{ 'display': showHeader == 'login' ? 'block' : 'none' }">Anmeldung</h3>
                    <h3 class="login-heading" ref="header-forgotpw" :style="{ 'display': showHeader == 'forgot-password' ? 'block' : 'none' }" style="opacity: 0">Passwort zurücksetzen</h3>
                    <hr
                        style="
                                height: 7px;
                                border: 1px solid white;
                                border-radius: 7px 7px 7px 7px;
                                opacity: 1;
                              "
                    />
                    <div
                        class="text-center font-weight-medium"
                        mt-4
                    ></div>
                    <h6>Bestellungen verwalten und koordinieren</h6>
                  </div>
                  <b-form @submit.prevent="onSubmit">
                    <input type="radio" name="rg" id="sign-in-radio" checked @click="switchToLogin()" />
                    <label for="sign-in-radio">Anmelden</label>
                    <input type="radio" name="rg" id="reset-radio" @click="switchToForgotPassword()" />
                    <label for="reset-radio">Passwort vergessen?</label>

                    <template v-if="page === 'login'">
                      <input
                        class="mb-3 input-field-color"
                        name="log_input-1"
                        v-model="form.userMail"
                        type="text"
                        style="text-align: left; margin-top: 10px;"
                        placeholder="Email"
                        required
                        ref="username-input"
                      />
                      <input
                        class="input-field-color"
                        id="log_input-2"
                        name="log_input-2"
                        v-model="form.userPassword"
                        type="password"
                        placeholder="Passwort"
                        @input="passwordError = false"
                      />
                    </template>

                    <template v-if="page === 'forgot-password'">
                      <input
                        class="mb-3 input-field-color"
                        name="log_input-1"
                        v-model="form.userMail"
                        type="text"
                        style="text-align: left; margin-top: 10px;"
                        placeholder="Email"
                        required
                        ref="username-input"
                      />
                    </template>

                    <div id="login-checkox">
                      <b-form-checkbox
                        v-model="form.logChecked"
                        id="log_checkbox-1"
                        name="log_checkbox-1"
                        value="1"
                        unchecked-value="0"
                        ref="remember-login-checkbox"
                      >
                        <p class="mb-3" style="margin-left: 3px">
                          Anmeldedaten merken
                        </p>
                      </b-form-checkbox>
                    </div>

                    <div class="mt-0 login-content-information">
                      <p class="mb-0 login-content-information-text" v-if="page === 'login'">
                        Keine Anmeldedaten?
                      </p>
                      <p class="mb-0 login-content-information-text" v-if="page === 'forgot-password'">
                        Passwort vergessen?
                      </p>
                      <p class="login-content-information-paragraph" v-if="page === 'login'">
                        Sie haben noch keine Anmeldedaten?<br />
                        Dann melden Sie sich
                        <a href="https://share.hsforms.com/1unagBT-3RP-Yas1tNJj-8Q4vffk">hier</a>!
                      </p>
                      <p class="login-content-information-paragraph" v-if="page === 'forgot-password'">
                        Geben Sie Ihre Email ein und klicken Sie auf Passwort zurücksetzen.
                        Sie erhalten von uns anschließend eine Mail, über welche Sie Ihr Passwort neu setzen können
                      </p>
                    </div>
                    <div class="button-wrapper">
                      <button type="submit" class="submit-button button" v-if="page === 'login'">Anmelden</button>
                      <button type="submit" class="submit-button button" v-if="page === 'forgot-password'">Password zurücksetzen</button>
                    </div>
                    <div v-if="passwordError === 1" class="password-error">
                      <p style="color:red;">Bitte geben Sie ein Passwort ein.</p>
                    </div>
                    <div v-if="passwordError === 2" class="password-error">
                      <p style="color:red;">Beim Login ist ein Fehler aufgetreten.</p>
                    </div>
                    <div v-if="passwordError === 3" class="password-error">
                      <p style="color:red;">Beim Zurücksetzen des Passwortes ist ein Fehler aufgetreten.</p>
                    </div>
                  </b-form>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </Appear>
</template>

<script>
import Appear from "./sub_components/Appear";
import {gsap} from "gsap";
import axios from "axios";

export default {
  name: "Login",
  components: { Appear },

  data() {
    return {
      page: "login", // or 'forgot-password'
      showHeader: "login",  // or 'forgot-password', only used for animation purposes
      showLoader: true,
      passwordError: 0,

      form: {
        userMail: "",
        userPassword: null,
        logChecked: 0,
      },
      animationObject1: {
        classes: "pulse",
        delay: 500,
        duration: 2000,
        iteration: "infinite",
      },
      animationObject2: {
        classes: "pulse",
        delay: 500,
        duration: 4000,
        iteration: "infinite",
      },
      animationObject3: {
        classes: "tada",
        delay: 500,
        duration: 20000,
        iteration: "infinite",
      },
      animationObject4: {
        classes: "swing",
        delay: 500,
        duration: 8000,
        iteration: "infinite",
      },
      animationObject5: {
        classes: "tada",
        delay: 500,
        duration: 18000,
        iteration: "infinite",
      },
      animationObject6: {
        classes: "pulse",
        delay: 500,
        duration: 5000,
        iteration: "infinite",
      },
      animationObject7: {
        classes: "bounce",
        delay: 500,
        duration: 10000,
        iteration: "infinite",
      },
      animationObject8: {
        classes: "swing",
        delay: 500,
        duration: 20000,
        iteration: "infinite",
      },
    };
  },
  computed: {
    queryParams() {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);

      const result = {};

      for (let key of urlParams.keys()) {
        result[key] = urlParams.get(key);
      }

      return result;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs["username-input"].focus();
    });

    gsap.timeline()
      .to(this.$refs.loader, { opacity: 0, duration: 1 })
      .call(() => { this.showLoader = false });
  },

  methods: {
    delayAsync(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    switchToForgotPassword() {
      this.page = "forgot-password";

      gsap.timeline()
        .to(this.$refs["header-login"], { duration: 0.05, opacity: 0 })
        .call(() => { this.showHeader = "forgot-password" })
        .to(this.$refs["header-forgotpw"], { duration: 0.3, opacity: 1 });

      gsap.timeline().set(this.$refs["remember-login-checkbox"].$el, { display: "none" });

      this.$nextTick(() => {
        this.$refs["username-input"].focus();
      });
    },

    switchToLogin() {
      this.page = "login";

      gsap.timeline()
        .to(this.$refs["header-forgotpw"], { duration: 0.05, opacity: 0 })
        .call(() => { this.showHeader = "login" })
        .to(this.$refs["header-login"], { duration: 0.3, opacity: 1 });

      gsap.timeline()
        .set(this.$refs["remember-login-checkbox"].$el, { display: "block" })
        .fromTo(this.$refs["remember-login-checkbox"].$el, { opacity: 0 }, { duration: 0.3, opacity: 1 });

      this.$nextTick(() => {
        this.$refs["username-input"].focus();
      });
    },

    async onSubmit(e) {
      if (this.page === 'login') {
        if (this.form.userPassword === "") {
          this.passwordError = 1;
          return;
        }

        const params = new URLSearchParams();
        params.append("email", this.form.userMail);
        params.append("password", this.form.userPassword);

        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          params: new URLSearchParams(window.location.search), // pass on all supplied params
        };

        try {
          const resp = await axios.post("/perform_login", params, config);

          if (resp.status === 200) {
            window.location.href = "/dashboard" + window.location.hash;
          } else {
            this.passwordError = 2;
          }
        } catch(e) {
          console.error(e);
          this.passwordError = 2;
        }
      } else {
        try {
          const resp = await axios.post("/api/password/reset", null, {
            params: {
              email: this.form.userMail
            }
          });

          if (resp.status === 200) {
            alert("Du erhälst in Kürze eine Email mit einem Link zum Zurücksetzen deines Passwortes.");
          } else {
            this.passwordError = 3;
          }
        } catch(e) {
          console.error(e);
          this.passwordError = 3;
        }
      }
    },
    gotoRegister() {
      this.$router.push("/register");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../css/login_pages.scss";

//input:not([type="radio"]) {
//  appearance: none;
//  background-color: $color-input;
//  display: block;
//  transition: 300ms ease;
//  border-radius: 7px;
//  border: 0;
//  max-height: 0;
//  margin: 0;
//  padding: 0 10px;
//  overflow: hidden;
//  width: 250px;
//  font-size: 16px;
//  outline: 0;
//}

//    show button text based on radio selection
#sign-in-radio:checked ~ button:before {
  content: "Anmelden";
}

#reset-radio:checked ~ button:before {
  content: "Passwort zurücksetzen";
}

//     set active label marker
#sign-in-radio:checked ~ label[for="sign-in-radio"],
#reset-radio:checked ~ label[for="reset-radio"] {
  color: #fd4545;
  &:after {
    border-bottom-color: #fd4545;
  }
}

label {
  position: relative;
  display: inline-block;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  color: $color-accent;
  transition: 300ms ease;
  width: calc(100% / 2 - 4px);

  //    pointer arrow
  &:after {
    content: "";
    border: 10px solid transparent;
    position: absolute;
    bottom: -10px;
    left: calc(50% - 10px);
    transition: inherit;
  }
}

</style>
