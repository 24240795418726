var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Appear",
    { attrs: { transition: ["100px", 0] } },
    [
      _c(
        "b-container",
        {
          staticClass: "fill-height login-container page",
          attrs: { fluid: "" },
        },
        [
          _vm.showLoader
            ? _c("div", { ref: "loader", staticClass: "loader-wrapper" }, [
                _c("div", { staticClass: "loader-4 center" }, [_c("span")]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "animate-css",
                rawName: "v-animate-css",
                value: _vm.animationObject1,
                expression: "animationObject1",
              },
            ],
            staticClass:
              "login-background image1 floating-image no-user-selection",
            staticStyle: { "z-index": "0" },
            attrs: { src: require("../../img/login_images/hearingaid1.png") },
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "animate-css",
                rawName: "v-animate-css",
                value: _vm.animationObject2,
                expression: "animationObject2",
              },
            ],
            staticClass:
              "login-background image2 floating-image no-user-selection",
            staticStyle: { "z-index": "0" },
            attrs: { src: require("../../img/login_images/hearing-aid2.png") },
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "animate-css",
                rawName: "v-animate-css",
                value: _vm.animationObject3,
                expression: "animationObject3",
              },
            ],
            staticClass: "image3 floating-image no-user-selection",
            staticStyle: {
              height: "120px",
              width: "80px",
              position: "absolute",
              opacity: "0.8 !important",
            },
            attrs: { src: require("../../img/login_images/enzo-q.png") },
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "animate-css",
                rawName: "v-animate-css",
                value: _vm.animationObject4,
                expression: "animationObject4",
              },
            ],
            staticClass: "image4 floating-image no-user-selection",
            staticStyle: {
              height: "120px",
              width: "80px",
              position: "absolute",
              "z-index": "0",
            },
            attrs: {
              src: require("../../img/login_images/widex-tv-play-1000-1000.png"),
            },
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "animate-css",
                rawName: "v-animate-css",
                value: _vm.animationObject5,
                expression: "animationObject5",
              },
            ],
            staticClass: "image5 floating-image no-user-selection",
            staticStyle: {
              height: "120px",
              width: "80px",
              position: "absolute",
            },
            attrs: {
              src: require("../../img/login_images/RS_TV_Streamer_white_rectangular.png"),
            },
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "animate-css",
                rawName: "v-animate-css",
                value: _vm.animationObject6,
                expression: "animationObject6",
              },
            ],
            staticClass: "image6 floating-image no-user-selection",
            staticStyle: {
              height: "90px",
              width: "60px",
              position: "absolute",
              "z-index": "0",
            },
            attrs: { src: require("../../img/login_images/enzo-q.png") },
          }),
          _vm._v(" "),
          _c("img", {
            directives: [
              {
                name: "animate-css",
                rawName: "v-animate-css",
                value: _vm.animationObject8,
                expression: "animationObject8",
              },
            ],
            staticClass:
              "login-background image8 floating-image no-user-selection",
            staticStyle: { "z-index": "0" },
            attrs: { src: require("../../img/login_images/hearing-aid2.png") },
          }),
          _vm._v(" "),
          _c(
            "b-row",
            {
              staticClass: "mx-auto",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "b-col",
                {
                  staticClass: "main-column mx-auto",
                  attrs: { sm: "8", md: "8", cols: "12" },
                },
                [
                  _c(
                    "b-card",
                    { staticClass: "shadow-lg main-center-card" },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            {
                              staticClass: "side-window pr-0 pl-0 pt-0",
                              attrs: { md: "2" },
                            },
                            [
                              _c("img", {
                                staticClass:
                                  "grey darken-4 login-image no-user-selection",
                                attrs: {
                                  src: require("../../img/login_images/Login-window.jpg"),
                                },
                              }),
                              _vm._v(" "),
                              _c("img", {
                                staticClass: "login-logo no-user-selection",
                                attrs: {
                                  src: require("../../img/Audoora_Services_Logos_Order_white.svg"),
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass: "mx-auto p-0",
                              staticStyle: { "text-align": "left" },
                              attrs: { cols: "12", md: "10" },
                            },
                            [
                              _c(
                                "b-card-text",
                                { staticClass: "mt-0 pa-7" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mb-4",
                                      staticStyle: { position: "relative" },
                                    },
                                    [
                                      _c(
                                        "h3",
                                        {
                                          ref: "header-login",
                                          staticClass: "login-heading",
                                          style: {
                                            display:
                                              _vm.showHeader == "login"
                                                ? "block"
                                                : "none",
                                          },
                                        },
                                        [_vm._v("Anmeldung")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "h3",
                                        {
                                          ref: "header-forgotpw",
                                          staticClass: "login-heading",
                                          staticStyle: { opacity: "0" },
                                          style: {
                                            display:
                                              _vm.showHeader ==
                                              "forgot-password"
                                                ? "block"
                                                : "none",
                                          },
                                        },
                                        [_vm._v("Passwort zurücksetzen")]
                                      ),
                                      _vm._v(" "),
                                      _c("hr", {
                                        staticStyle: {
                                          height: "7px",
                                          border: "1px solid white",
                                          "border-radius": "7px 7px 7px 7px",
                                          opacity: "1",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("div", {
                                        staticClass:
                                          "text-center font-weight-medium",
                                        attrs: { "mt-4": "" },
                                      }),
                                      _vm._v(" "),
                                      _c("h6", [
                                        _vm._v(
                                          "Bestellungen verwalten und koordinieren"
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.onSubmit.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("input", {
                                        attrs: {
                                          type: "radio",
                                          name: "rg",
                                          id: "sign-in-radio",
                                          checked: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.switchToLogin()
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "sign-in-radio" } },
                                        [_vm._v("Anmelden")]
                                      ),
                                      _vm._v(" "),
                                      _c("input", {
                                        attrs: {
                                          type: "radio",
                                          name: "rg",
                                          id: "reset-radio",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.switchToForgotPassword()
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        { attrs: { for: "reset-radio" } },
                                        [_vm._v("Passwort vergessen?")]
                                      ),
                                      _vm._v(" "),
                                      _vm.page === "login"
                                        ? [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.form.userMail,
                                                  expression: "form.userMail",
                                                },
                                              ],
                                              ref: "username-input",
                                              staticClass:
                                                "mb-3 input-field-color",
                                              staticStyle: {
                                                "text-align": "left",
                                                "margin-top": "10px",
                                              },
                                              attrs: {
                                                name: "log_input-1",
                                                type: "text",
                                                placeholder: "Email",
                                                required: "",
                                              },
                                              domProps: {
                                                value: _vm.form.userMail,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.form,
                                                    "userMail",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.form.userPassword,
                                                  expression:
                                                    "form.userPassword",
                                                },
                                              ],
                                              staticClass: "input-field-color",
                                              attrs: {
                                                id: "log_input-2",
                                                name: "log_input-2",
                                                type: "password",
                                                placeholder: "Passwort",
                                              },
                                              domProps: {
                                                value: _vm.form.userPassword,
                                              },
                                              on: {
                                                input: [
                                                  function ($event) {
                                                    if (
                                                      $event.target.composing
                                                    ) {
                                                      return
                                                    }
                                                    _vm.$set(
                                                      _vm.form,
                                                      "userPassword",
                                                      $event.target.value
                                                    )
                                                  },
                                                  function ($event) {
                                                    _vm.passwordError = false
                                                  },
                                                ],
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.page === "forgot-password"
                                        ? [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.form.userMail,
                                                  expression: "form.userMail",
                                                },
                                              ],
                                              ref: "username-input",
                                              staticClass:
                                                "mb-3 input-field-color",
                                              staticStyle: {
                                                "text-align": "left",
                                                "margin-top": "10px",
                                              },
                                              attrs: {
                                                name: "log_input-1",
                                                type: "text",
                                                placeholder: "Email",
                                                required: "",
                                              },
                                              domProps: {
                                                value: _vm.form.userMail,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing) {
                                                    return
                                                  }
                                                  _vm.$set(
                                                    _vm.form,
                                                    "userMail",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { attrs: { id: "login-checkox" } },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              ref: "remember-login-checkbox",
                                              attrs: {
                                                id: "log_checkbox-1",
                                                name: "log_checkbox-1",
                                                value: "1",
                                                "unchecked-value": "0",
                                              },
                                              model: {
                                                value: _vm.form.logChecked,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "logChecked",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.logChecked",
                                              },
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass: "mb-3",
                                                  staticStyle: {
                                                    "margin-left": "3px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        Anmeldedaten merken\n                      "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mt-0 login-content-information",
                                        },
                                        [
                                          _vm.page === "login"
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 login-content-information-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Keine Anmeldedaten?\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.page === "forgot-password"
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 login-content-information-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Passwort vergessen?\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.page === "login"
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "login-content-information-paragraph",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Sie haben noch keine Anmeldedaten?"
                                                  ),
                                                  _c("br"),
                                                  _vm._v(
                                                    "\n                      Dann melden Sie sich\n                      "
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href: "https://share.hsforms.com/1unagBT-3RP-Yas1tNJj-8Q4vffk",
                                                      },
                                                    },
                                                    [_vm._v("hier")]
                                                  ),
                                                  _vm._v(
                                                    "!\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.page === "forgot-password"
                                            ? _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "login-content-information-paragraph",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      Geben Sie Ihre Email ein und klicken Sie auf Passwort zurücksetzen.\n                      Sie erhalten von uns anschließend eine Mail, über welche Sie Ihr Passwort neu setzen können\n                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "button-wrapper" },
                                        [
                                          _vm.page === "login"
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "submit-button button",
                                                  attrs: { type: "submit" },
                                                },
                                                [_vm._v("Anmelden")]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.page === "forgot-password"
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "submit-button button",
                                                  attrs: { type: "submit" },
                                                },
                                                [
                                                  _vm._v(
                                                    "Password zurücksetzen"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.passwordError === 1
                                        ? _c(
                                            "div",
                                            { staticClass: "password-error" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [
                                                  _vm._v(
                                                    "Bitte geben Sie ein Passwort ein."
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.passwordError === 2
                                        ? _c(
                                            "div",
                                            { staticClass: "password-error" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [
                                                  _vm._v(
                                                    "Beim Login ist ein Fehler aufgetreten."
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.passwordError === 3
                                        ? _c(
                                            "div",
                                            { staticClass: "password-error" },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticStyle: { color: "red" },
                                                },
                                                [
                                                  _vm._v(
                                                    "Beim Zurücksetzen des Passwortes ist ein Fehler aufgetreten."
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }