import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faTimes,
    faSave,
    faTruck,
    faPhone,
    faIndustry,
    faArrowRight,
    faHistory,
    faArrowCircleLeft,
    faChevronRight,
    faFileInvoice,
    faEdit,
    faBuilding,
    faPlus,
    faPlusCircle,
    faSyncAlt,
    faComment,
    faInfoCircle,
    faTrashAlt,
    faEllipsisH,
    faTimesCircle,
    faHouseUser,
    faShoppingCart,
    faCoins,
    faTree,
    faTrash,
    faHome,
    faUserEdit,
    faAngleRight,
    faChartBar,
    faSignOutAlt,
    faChevronDown,
    faKey,
    faBox,
    faTruckMoving,
    faCogs,
    faUserCircle,
    faSync,
    faCubes,
    faExclamationTriangle,
    faUser,
    faPen,
    faQuestionCircle,
    faExclamationCircle,
    faCheck,
    faEnvelope,
    faLongArrowAltRight,
    faInfo,
    faUserPlus,
    faCheckCircle,
    faLowVision
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Vue from 'vue';
import VModal from 'vue-js-modal';
import axios from 'axios';
import VueAxios from 'vue-axios';
import vSelect from 'vue-select'

import "vue2-datepicker/locale/de";
import {
    BootstrapVue,
    IconsPlugin
} from 'bootstrap-vue';
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";

// setup font awesome
library.add(faTimes,
    faSyncAlt,
    faTruck,
    faSave,
    faPhone,
    faIndustry,
    faHistory,
    faArrowRight,
    faBuilding,
    faArrowCircleLeft,
    faChevronRight,
    faPlus,
    faPlusCircle,
    faFileInvoice,
    faEdit,
    faInfo,
    faComment,
    faInfoCircle,
    faTrashAlt,
    faEllipsisH,
    faTimesCircle,
    faHouseUser,
    faFileInvoice,
    faShoppingCart,
    faCoins,
    faTree,
    faHome,
    faTrash,
    faUserEdit,
    faAngleRight,
    faChartBar,
    faSignOutAlt,
    faChevronDown,
    faKey,
    faBox,
    faTruckMoving,
    faCogs,
    faUserCircle,
    faSync,
    faCubes,
    faExclamationTriangle,
    faUser,
    faPen,
    faQuestionCircle,
    faExclamationCircle,
    faCheck,
    faEnvelope,
    faLongArrowAltRight,
    faUserPlus,
    faCheckCircle,
    faLowVision);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueAxios, axios);

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize("en", en);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect);

Vue.use(VModal, { dialog: true });;

export default Vue;