import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        permissions: [],
        permissionsLoaded: false,
        branches: [],
        id: null,
        activeBranch: null,
    },
    mutations: {
        setPermissions(state, payload) {
            state.permissions = payload;
            state.permissionsLoaded = true;
        },
        setUserId(state, payload) {
            state.id = payload;
        },
        setUserBranches(state, payload) {
            state.branches = payload.map(branch => {
                return {
                    id: branch.id,
                    name: branch.branchId,
                    displayName: branch.addresses[0].name,
                    active: branch.active
                };
            });
        },
        setActiveBranch(state, payload) {
            if (typeof payload != "number") throw new Error("The branch is identified by a numerical id");
            state.activeBranch = payload;
        }
    },
    actions: {},
    modules: {},
    getters: {
        getActiveBranch(state) {
            return state.activeBranch;
        },
        getActiveBranchId(state) {
            const branch = state.branches.find(b => b.id === state.activeBranch);
            return branch ? branch.name : null;
        },
        getAllBranches(state) {
            return state.branches;
        },
        getActiveBranchActivationState(state) {
            const branch = state.branches.find(b => b.id === state.activeBranch);
            return branch ? branch.active : false;
        },
        getAllPermissions(state) {
            return state.permissions;
        },
        getPermissionsLoaded(state) {
            return state.permissionsLoaded;
        }
    }
});